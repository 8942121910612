<template>
  <div class="home">
    <div id="chat-container" ref="chatContainer" v-if="messages.length > 0">
      <div v-for="(message, index) in messages" :key="index">
        <div v-if="message.type === 'thinking'" style="display: flex">
          <img
            style="margin-right: 10px"
            class="logoIcon"
            src="/static/img/logoIcon.png"
          />
          <div :class="['message', message.type]">
            <div class="loading-container">
              <img
                src="/static/img/chatLoading.png"
                alt="Loading"
                class="loading-image"
              />
              <div class="loading-text">思考中...</div>
            </div>
            <span>{{ message.content }}</span>
          </div>
        </div>
        <div v-else-if="message.type === 'file'" class="message file-message">
          <div class="file-icon">{{ getFileIcon(message.name) }}</div>
          <span>上传文件：{{ message.name }}</span>
        </div>
        <div v-else style="display: flex; position: relative">
          <img
            v-if="message.type === 'assistant-message'"
            class="logoIcon"
            src="/static/img/logoIcon.png"
          />
          <el-tooltip
            v-if="message.type === 'assistant-message' && message.content"
            content="复制"
            placement="bottom"
          >
            <i
              class="chatCopy el-icon-document-copy"
              @click="copyChatMessage(message.content)"
            ></i>
          </el-tooltip>
          <div :class="['message', message.type]">
            <el-collapse
              v-model="activeName"
              accordion
              v-if="
                message.type === 'assistant-message' && message.thinkContent
              "
            >
              <el-collapse-item name="1">
                <template slot="title">
                  <i
                    class="el-icon-circle-check"
                    style="
                      color: #409eff;
                      font-size: 16px;
                      font-weight: 700;
                      margin-right: 5px;
                    "
                  ></i>
                  思考过程
                </template>
                <div>{{ message.thinkContent }}</div>
              </el-collapse-item>
            </el-collapse>
            <span v-html="compiledMarkdown(message.content)"></span>
            <!--<img  v-if="message.type === 'user-message'" :src="user.avatar" />-->
          </div>
          <!--<span class="timestamp">{{ message.timestamp }}</span>-->
        </div>
      </div>
    </div>
    <div v-else class="chat-empty">
      <div style="position: relative">
        <img src="/static/img/chat_bg.png" style="width: 80%;" />
        <p class="text">实验百事通，快速精准，在线解答，实验难题，轻松应对。</p>
      </div>
    </div>
    <div id="input-container">
      <div style="width: 100%">
        <el-input
          type="textarea"
          v-model="userInput"
          placeholder="请输入您的问题..."
          style="height: 100px;"
          @keydown.enter.native.prevent="handleEnter"
          @keyup.enter.native="userInput = userInput.replace(/\n/g, '')"
        ></el-input>
      </div>
      <div style="width: 100%">
        <el-tooltip
          class="item"
          effect="dark"
          :content="!userInput ? '请输入您的问题...' : '发送'"
          placement="top-start"
        >
          <el-button
            :disabled="!userInput"
            style="float: right; margin: 0px 10px"
            type="primary"
            @click="sendMessage"
            size="mini"
            icon="el-icon-position"
            circle
          >
          </el-button>
        </el-tooltip>
        <el-upload
          style="float: right"
          :before-upload="handleFileUpload"
          :on-change="handleFileChange"
          :show-file-list="false"
          action="#"
        >
          <el-tooltip
            class="item"
            effect="dark"
            content="支持各类文档和图片"
            placement="top"
          >
            <el-button
              style="
                float: right;
                margin-right: 10px;
                font-size: 22px;
                padding-top: 5px;
                height: 30px;
              "
              type="text"
              size="medium"
              icon="el-icon-paperclip"
            >
            </el-button>
          </el-tooltip>
        </el-upload>
        <div
          style="
            margin-left: 10px;
            clear: both;
            margin-bottom: 10px;
            display: inline-block;
            line-height: 30px;
          "
          v-if="fileName"
        >
          <i :class="getFileIcon(fileName)" style="color: #409eff"></i>
          {{ fileName }}
          <el-tooltip class="item" effect="dark" content="删除" placement="top">
            <el-button
              type="text"
              style="font-size: 16px; color: #f56c6c; margin-left: 10px"
              icon="el-icon-delete"
              @click="handleFileDelete"
            >
            </el-button>
          </el-tooltip>
        </div>
        <el-progress
          v-if="uploading"
          :percentage="uploadProgress"
          status="success"
          style="margin-top: 10px"
        ></el-progress>
      </div>
    </div>
  </div>
</template>
    
<script>
import { marked } from "marked";
import hljs from "highlight.js";
import "highlight.js/styles/github-dark-dimmed.css"; // 选择你喜欢的样式
import { copyText } from "@/util/copyText";

export default {
  name: "IntellectChat",
  data() {
    return {
      messages: [],
      activeName: "1",
      userInput: "",
      fileName: "",
      uploading: false,
      uploadProgress: 0,
      ws: null,
      thinkingMessageId: null, // 用于跟踪当前的思考消息
      thinkContent: "",
    };
  },
  created() {
    // 配置marked
    marked.setOptions({
      highlight: function (code, language) {
        const validLanguage = hljs.getLanguage(language)
          ? language
          : "plaintext";
        return hljs.highlight(validLanguage, code).value;
      },
      langPrefix: "hljs language-",
    });
  },
  computed: {},
  mounted() {
    this.initWebSocket();
  },
  methods: {
    initWebSocket() {
      this.ws = new WebSocket("wss://lablink.ac.cn/ws/");

      this.ws.onopen = () => {
        console.log("WebSocket连接已建立");
        //this.addSystemMessage("连接已建立，您可以开始对话了");
      };

      this.ws.onclose = () => {
        //this.$message.error("WebSocket连接已关闭");
        console.log("WebSocket连接已关闭");
        //this.addSystemMessage("连接已断开，请刷新页面重新连接");
      };

      this.ws.onmessage = (event) => {
        const data = JSON.parse(event.data);

        if (data.type === "thinking") {
          this.updateThinkingMessage(data.content);
        } else if (data.type === "content" || data.type === "complete") {
          if (data.type === "content") {
            // 获取当前消息数组的长度
            const lastIndex = this.messages.length - 1;

            // 检查消息数组是否为空
            if (this.messages.length === 0) {
              // 如果消息数组为空，直接添加新消息
              this.addMessage({
                type: "assistant-message",
                content: data.content,
                thinkContent: this.thinkContent,
                timestamp: this.formatTimestamp(),
              });
            } else {
              // 如果消息数组不为空，检查最后一条消息是否是助手消息
              const lastMessage = this.messages[lastIndex];
              if (lastMessage.type === "assistant-message") {
                // 追加内容到最后一条消息
                lastMessage.content += data.content;
                this.scrollToBottom();
              } else {
                // 如果最后一条消息不是助手消息，添加新消息
                this.addMessage({
                  type: "assistant-message",
                  content: data.content,
                  thinkContent: this.thinkContent,
                  timestamp: this.formatTimestamp(),
                });
              }
            }
            this.activeName = "0";
            this.thinkContent = "";
            this.clearThinkingMessage(); // 清除思考消息
          } else if (data.type === "complete") {
            // 当 data.type === "complete" 时，表示内容传输完成
            console.log("Content transmission complete");
          }
        } else if (data.type === "error") {
          this.addMessage({
            type: "error-message",
            content: data.content,
            timestamp: this.formatTimestamp(),
          });
          this.thinkContent = "";
          this.clearThinkingMessage(); // 清除思考消息
        }
      };
    },
    addSystemMessage(message) {
      this.addMessage({
        type: "system-message",
        content: message,
        timestamp: this.formatTimestamp(),
      });
    },
    addMessage(message) {
      this.messages.push(message);
      this.scrollToBottom();
    },
    updateThinkingMessage(content) {
      if (this.thinkingMessageId === null) {
        // 如果没有思考消息，则添加一条新的
        this.thinkingMessageId = this.messages.length;
        this.thinkContent = content;
        this.addMessage({
          type: "thinking",
          content: content,
          timestamp: this.formatTimestamp(),
        });
      } else {
        // 如果有思考消息，则更新内容
        this.messages[this.thinkingMessageId].content += content;
        this.thinkContent += content;
        this.scrollToBottom();
      }
    },
    clearThinkingMessage() {
      if (this.thinkingMessageId !== null) {
        this.messages.splice(this.thinkingMessageId, 1); // 移除思考消息
        this.thinkingMessageId = null;
      }
    },
    handleFileUpload(file) {
      this.fileName = file.name;
      this.uploading = true;
      this.uploadProgress = 0;

      const fileReader = new FileReader();
      fileReader.onload = () => {
        this.ws.send(
          JSON.stringify({
            type: "file",
            name: file.name,
            content: fileReader.result,
          })
        );

        // 模拟上传进度
        const interval = setInterval(() => {
          this.uploadProgress += 10;
          if (this.uploadProgress >= 100) {
            clearInterval(interval);
            this.uploading = false;
            this.uploadProgress = 0;
          }
        }, 200);
      };

      fileReader.readAsDataURL(file);
      return false; // 阻止默认上传行为
    },
    handleFileChange(file, fileList) {
      this.fileName = file.name;
    },
    handleEnter(event) {
      event.preventDefault();
      event.stopPropagation(); // 新增阻止事件冒泡
      if (!event.shiftKey) {
        // 允许shift+enter换行
        this.sendMessage();
      }
    },
    handleFileDelete() {
      this.ws.send(
        JSON.stringify({
          type: "delete-file",
          name: this.fileName,
        })
      );
      this.fileName = "";
      this.$message.success("文件已删除");
    },
    sendMessage() {
      if (this.userInput.trim()) {
        this.ws.send(
          JSON.stringify({
            type: "message",
            content: this.userInput.trim(),
          })
        );

        this.addMessage({
          type: "user-message",
          content: this.userInput.trim(),
          timestamp: this.formatTimestamp(),
        });

        this.userInput = "";
        this.clearThinkingMessage(); // 清除思考消息
      } else {
        this.$message.warning("请输入您的问题...");
      }
    },
    compiledMarkdown(data) {
      return marked(data);
    },
    copyChatMessage(message) {
      if (!message) {
        this.$message.warning("暂无内容，无法复制");
      } else {
        copyText(message, this);
      }
    },
    getFileIcon(fileName) {
      const extension = fileName.split(".").pop().toLowerCase();
      switch (extension) {
        case "pdf":
          return "el-icon-document-checked";
        case "doc":
        case "docx":
          return "el-icon-document";
        case "txt":
          return "el-icon-edit-outline";
        case "png":
        case "jpg":
        case "jpeg":
        case "bmp":
        case "webp":
        case "gif":
          return "el-icon-picture-outline";
        case "csv":
        case "xls":
        case "xlsx":
          return "el-icon-date";
        default:
          return "el-icon-tickets";
      }
    },
    formatTimestamp() {
      const now = new Date();
      return now.toLocaleTimeString();
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const chatContainer = this.$refs.chatContainer;
        chatContainer.scrollTop = chatContainer.scrollHeight;
      });
    },
  },
  beforeDestroy() {
    if (this.ws) {
      this.ws.close();
    }
  },
};
</script>
    
    <style  >
#chat-container {
  background-color: white;
  min-height: 300px;
  height: auto;
  max-height: 70vh;
  overflow-y: auto;
  margin: 20px 0px;
  padding: 10px;
}
#chat-container .base-collapse-item__header {
  height: 34px;
  line-height: 34px;
  color: #666;
}
#chat-container .base-collapse,
#chat-container .base-collapse-item__header,
#chat-container .base-collapse-item__wrap {
  background: #f3f5fc;
}
#chat-container .base-collapse-item:before {
  box-shadow: none;
}
#chat-container .base-collapse-item__wrap {
  padding-bottom: 10px;
  border-radius: 0px 0px 4px 4px;
}
#chat-container .base-collapse {
  margin-bottom: 10px;
  border-radius: 4px 4px 0px 0px;
}
#chat-container .base-collapse-item__content {
  color: #999;
}
#chat-container .message {
  margin-bottom: 15px;
  padding: 6px 12px;
  border-radius: 4px;
  max-width: 85%; /* 设置最大宽度 */
  display: block; /* 每条消息独占一行 */
  word-wrap: break-word;
  line-height: 24px;
  clear: both;
}
/* 增加代码块样式 */
#chat-container .message pre {
  background-color: #181d28;
  border-radius: 4px;
  padding: 16px;
  overflow-x: auto;
  margin: 10px 0;
}

#chat-container .message code {
  font-family: "Courier New", Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
}

#chat-container .message pre code {
  background-color: #181d28;
  padding: 0;
}
#chat-container .message p {
  margin-block-start: 0px;
  margin-block-end: 0px;
}
#chat-container .logoIcon {
  height: 25px;
  margin-top: 15px;
}
#chat-container .chatCopy {
  top: 55px;
  position: absolute;
  left: 5px;
  font-size: 18px;
  cursor: pointer;
}
#chat-container .chatCopy:hover {
  color: #85ce61;
}
#chat-container .user-message {
  background-color: #e3f2fd; /* 用户消息背景色 */
  margin-left: auto; /* 靠右显示 */
  float: right;
}
#chat-container .user-message img {
  margin-right: 0px;
  margin-left: 8px;
}
#chat-container .assistant-message {
  background-color: #fff; /* 助手消息背景色 */
  margin-right: auto; /* 靠左显示 */
  float: left;
  font-size: 15px;
}
#chat-container .assistant-message ol {
  padding-inline-start: 0px;
}
#chat-container .assistant-message li {
  line-height: 24px;
}
#chat-container .thinking {
  background-color: #f3f5fc; /* 思考消息背景色 */
  color: #999; /* 思考消息文字颜色 */
  padding: 8px;
  border-radius: 4px;
  display: block; /* 思考消息独占一行 */
  float: left;
}

#input-container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  border: 1px solid #ddd;
  padding: 5px;
  border: 1px solid #bfc3de;
  border-radius: 4px;
  background: #f3f4f6;
}
#input-container .base-input__inner,
#input-container .base-textarea__inner {
  border: none;
  background: #f3f4f6;
  line-height: 30px;
  resize: none;
  padding: 0px 5px;
  resize: none !important; /* 禁用文本域拖拽 */
  overflow-y: auto !important; /* 保持滚动条 */
}

#chat-container .file-message {
  display: flex;
  align-items: center;
  gap: 10px;
}

#chat-container .file-icon {
  width: 32px;
  height: 32px;
  background-color: #e3f2fd;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

#chat-container .supported-files {
  margin-top: 10px;
  font-size: 14px;
  color: #666;
}

#chat-container .timestamp {
  font-size: 12px;
  color: #999;
  margin-top: 5px;
  text-align: right;
}

#chat-container .error-message {
  background-color: #ffebee;
  color: #c62828;
  padding: 10px;
  border-radius: 8px;
  margin: 10px 0;
}
/* 加载容器 */
#chat-container .loading-container {
  display: flex;
  align-items: center; /* 垂直居中 */
  font-family: Arial, sans-serif;
}

/* 加载图片 */
#chat-container .loading-image {
  width: 24px; /* 图片大小 */
  height: 24px; /* 图片大小 */
  border-radius: 50%; /* 使图片变成圆形（可选） */
  animation: spin 1s linear infinite; /* 应用旋转动画 */
}

/* 加载文字 */
#chat-container .loading-text {
  margin-left: 3px; /* 文字与图片的间距 */
  font-size: 14px;
  color: #666;
}

/* 定义旋转动画 */
@keyframes spin {
  0% {
    transform: rotate(0deg); /* 起始角度 */
  }
  100% {
    transform: rotate(360deg); /* 结束角度 */
  }
}
#chat-container::-webkit-scrollbar {
  width: 4px;
}
#chat-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  opacity: 0.2;
  background: fade(#8398de, 60%);
}
.chat-empty {
  text-align: center;
  height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.chat-empty .text {
  font-size: 16px;
  margin: 0px;
  position: absolute;
  left: 40%;
  top: 60%;
  color: #8b96a5;
  text-align: left;
}

</style>